import React, { useEffect, useState } from 'react';
import {Params, useLocation, useNavigate, useParams} from 'react-router-dom';
import { Post } from "../../../Page/Landing/Landing";
import './postpage.css';
import Carousel from "../../Carousel/Carousel";
import { useReactions } from "../../../Hooks/UseReactions";
import axios from 'axios';
import Loading from "../../Loading/Loading";

type ReactionState = {
    happy: number;
    thumbs: number;
    calm: number;
};


const apiUrl = process.env.REACT_APP_API_URL ?? "";
const appUrl = process.env.REACT_APP_APP_URL;

const PostPage: React.FC = () => {

    // TODO try to add the left and right swipe

    const location = useLocation();
    const { postId } = useParams<Params>();
    const [post, setPost] = useState<Post | null>(location.state as Post | null);
    const navigate = useNavigate()

    useEffect(() => {
        if (window.location.pathname.includes("bremen")) {
            document.body.className = "bremen";
        } else {
            document.body.className = "stanford";
        }
    }, []);


    useEffect(() => {
        if (!post) {
            if (postId) {
                fetchUniquePost(parseInt(postId));
            }
        }
    }, [post]);

    const fetchUniquePost = async (postId: number) => {
        try {
            const response = await axios.get<Post>(`${apiUrl}/api/post/${postId}`);
            setPost(response.data);
        } catch (error) {
            console.error('Error fetching post:', error);
        }
    };

    const { reactions, selectedEmoji, hasReactions, handleEmojiClick } = useReactions({
        postId: post?.id ?? 0,
        apiUrl,
        initialReactions: post?.reactions.reduce(
            (acc, reaction) => ({ ...acc, [reaction.Reaction.name as keyof typeof acc]: reaction.count }),
            { happy: 0, thumbs: 0, calm: 0 }
        ),
    });


    if (!post) {
        return <Loading />;
    }

    const formattedDate = new Intl.DateTimeFormat('fr-FR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }).format(new Date(post.date));

    const backToMenu = () => {
        navigate(`/stanford`);
    };


    return (
        <div className="post-page">
            <div className={"post-header"}>
                <h2>{post.title}</h2>
                <div onClick={backToMenu}>
                    <span>Retour</span>
                    <img src={"/images/arrow_back.svg"} alt={"Retour"}/>
                </div>
            </div>
            <p>{post.text}</p>
            <Carousel images={post.images} />

            <div className="emoji-set">
                <div>
                    <img
                        src={selectedEmoji.thumbs ? '/images/emoji_thumb_selected.svg' : '/images/emoji_thumb.svg'}
                        alt="Pouce"
                        onClick={() => handleEmojiClick('thumbs')}
                    />
                    <span>{reactions.thumbs}</span>
                </div>
                <div>
                    <img
                        src={selectedEmoji.happy ? '/images/emoji_happy_selected.svg' : '/images/emoji_happy.svg'}
                        alt="Joie"
                        onClick={() => handleEmojiClick('happy')}
                    />
                    <span>{reactions.happy}</span>
                </div>
                <div>
                    <img
                        src={selectedEmoji.calm ? '/images/emoji_calm_selected.svg' : '/images/emoji_calm.svg'}
                        alt="Calme"
                        onClick={() => handleEmojiClick('calm')}
                    />
                    <span>{reactions.calm}</span>
                </div>
            </div>
            <div className={"footer"}>
                <span className={"bar"}></span>
                <span>{formattedDate} - {post.country.name}</span>
            </div>
        </div>
    );
};

export default PostPage;
