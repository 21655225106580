import React, {useEffect, useState} from 'react';
import "./card.css"
import Carousel from "../../Carousel/Carousel";
import {Post} from "../../../Page/Landing/Landing";
import axios from "axios";
import Cookies from 'js-cookie';
import {useReactions} from "../../../Hooks/UseReactions";

interface CardProps {
    post: Post;
}


const MAX_DISPLAY_LENGTH = 200;

const apiUrl = process.env.REACT_APP_API_URL ?? "";
const appUrl = process.env.REACT_APP_APP_URL;

const Card: React.FC<CardProps> = ({ post }) => {


    const [showEmojiSelector, setShowEmojiSelector] = useState(false);


    const [showFullText, setShowFullText] = useState(false);

    function showText(){
        setShowFullText(true);
    }

    const isWebShareAvailable = navigator.share !== undefined;


    const formattedDate = new Intl.DateTimeFormat('fr-FR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }).format(new Date(post.date));

    const { reactions, selectedEmoji, hasReactions, handleEmojiClick } = useReactions({
        postId: post.id,
        apiUrl,
        initialReactions: post.reactions.reduce(
            (acc, reaction) => ({ ...acc, [reaction.Reaction.name as keyof typeof acc]: reaction.count }),
            { happy: 0, thumbs: 0, calm: 0 }
        ),
    });




    const handleShareClick = async () => {
        const shareUrl = appUrl + '#post' + post.id;
        if (isWebShareAvailable) {
            try {
                await navigator.share({
                    title: 'Partager mon contenu de Brême',
                    text: 'Voici un post publié par Paul ! Clique sur ce lien : \n',
                    url: shareUrl,
                });
            } catch (error) {
                console.error('Error sharing content:', error);
            }
        } else {
            try {
                await navigator.clipboard.writeText(shareUrl);
                alert('Le lien a bien été copié');
            } catch (error) {
                console.error('Error copying link to clipboard:', error);
                alert('Une erreur est survenue pendant la copie du lien');
            }
        }
    }

    const toggleEmojiSelector = () => {
        setShowEmojiSelector((prevShowEmojiSelector) => !prevShowEmojiSelector);
    };

    useEffect(() => {
        scrollToPost().then(r => "hash handler");
    }, []);

    const scrollToPost = async () => {
        let hash = getHashId();
        if (hash) {
            let scrolled = false;
            while (!scrolled) {
                const element = document.getElementById(hash);
                if (element) {
                    element.scrollIntoView();
                    scrolled = true
                }
                await timeout(500)
            }
        }
    };

    function timeout(delay: number) {
        return new Promise( res => setTimeout(res, delay) );
    }

    const getHashId = () => {
        const hash = window.location.hash;
        if (hash && hash.startsWith("#post")) {
            return hash.substring(1)
        }
        return null;
    };


    return (
        <div className="card" id={"post"+post.id}>
            <div className="header">
                <h2>{post.title}</h2>
                <img onClick={handleShareClick} src="/images/share.svg" alt="Partager"/>
            </div>
            <div className={"content"}>
                <p className="text">
                    {showFullText ? post.text : post.text.slice(0, MAX_DISPLAY_LENGTH)}
                    {post.text.length > MAX_DISPLAY_LENGTH && !showFullText && (
                        <span>
                        {' '}
                            <button onClick={showText} className="see-more">... Voir Plus</button>
                    </span>
                    )}
                </p>
                <Carousel images={post.images} />
            </div>
            <div className="footer">
                <span>{formattedDate} - {post.country.name}</span>
                <div className={`emoji-selector ${showEmojiSelector ? 'visible' : ''}`}>
                    <img
                        src={selectedEmoji.thumbs ? '/images/emoji_thumb_selected.svg' : '/images/emoji_thumb.svg'}
                        alt="Pouce"
                        onClick={() => handleEmojiClick('thumbs')}
                    />
                    <img
                        src={selectedEmoji.calm ? '/images/emoji_calm_selected.svg' : '/images/emoji_calm.svg'}
                        alt="Calme"
                        onClick={() => handleEmojiClick('calm')}
                    />
                    <img
                        src={selectedEmoji.happy ? '/images/emoji_happy_selected.svg' : '/images/emoji_happy.svg'}
                        alt="Joie"
                        onClick={() => handleEmojiClick('happy')}
                    />
                    <img className="add" src="/images/emoij_add.svg" alt="Ajouter une réaction" onClick={toggleEmojiSelector}/>
                </div>
            </div>

            {hasReactions && (
                <div className="emoji-pill">
                    {reactions.thumbs > 0 && (
                        <div>
                            <span>{reactions.thumbs}</span>
                            <img
                                src="/images/emoji_thumb.svg"
                                alt="Pouce"
                            />
                        </div>
                    )}
                    {reactions.happy > 0 && (
                        <div>
                            <span>{reactions.happy}</span>
                            <img
                                src="/images/emoji_happy.svg"
                                alt="Joie"
                            />
                        </div>
                    )}
                    {reactions.calm > 0 && (
                        <div>
                            <span>{reactions.calm}</span>
                            <img
                                src="/images/emoji_calm.svg"
                                alt="Calme"
                            />
                        </div>
                    )}
                </div>
            )}
        </div>

    );
}

export default Card;