import React from 'react';
import './loading.css'

const Loading: React.FC = () => {
    return (
        <div className='loader loader1'>
            <div>
                <div>
                    <div>
                        <div>
                            <div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loading;