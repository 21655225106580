import { initializeApp } from 'firebase/app';
import { getMessaging, getToken as getFCMToken } from 'firebase/messaging';
import axios from 'axios';

const firebaseConfig = {
    apiKey: "AIzaSyCAGe9ulwLYoI28lMDZc-tcWJa-koIP2j8",
    authDomain: "bremen-b1254.firebaseapp.com",
    projectId: "bremen-b1254",
    storageBucket: "bremen-b1254.appspot.com",
    messagingSenderId: "763090211430",
    appId: "1:763090211430:web:4c4ee8d7abf6903dc353a7",
    measurementId: "G-31BBVLWK5Y"
};

const apiUrl = process.env.REACT_APP_API_URL;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Cloud Messaging
const messaging = getMessaging(app);

// Register the service worker for Firebase Cloud Messaging
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/firebase-messaging-sw.js') // Update the path to your service worker script
            .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch((error) => {
                console.error('Service Worker registration failed:', error);
            });
    });
}

if (typeof Notification !== 'undefined') {
    if (Notification.permission !== 'granted') {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('notif allowed');
            } else {
                console.log('notif not allowed');
            }
        });
    }
}

export const getToken = (setTokenFound) => {
    return getFCMToken(messaging, {vapidKey: 'BFGYJQhHISd4a62qoMaANdFVKVO1BRMoDRZBE4WpUBPkNHnFQteQf3SN0A7XuYpQkcOqMHrGXcYEA8VBWbG2KUY'}).then((currentToken) => {
        if (currentToken) {
            console.log(currentToken);
            axios
                .post(`${apiUrl}/api/firebase`, { token: currentToken })
                .then((response) => {
                    console.log('API response:', response.data);
                })
                .catch((error) => {
                    console.error('API error:', error);
                });
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
}