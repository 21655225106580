import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

interface AuthGuardProps {
    children: ReactNode; // Specify the type explicitly
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
    const navigate = useNavigate();

    if (!Cookies.get('token')) {
        navigate('/login');
        return null;
    }

    return <>{children}</>;
};

export default AuthGuard;