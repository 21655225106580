import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Image} from "../Landing/Landing";
import './album.css'


const apiUrl = process.env.REACT_APP_API_URL;

const Album = () => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [images, setImages] = useState<Image[]>([]);

    const [imageLoaded, setImageLoaded] = useState(Array(images.length).fill(false));

    const handleImageLoad = (index: number) => {
        const updatedImageLoaded = [...imageLoaded];
        updatedImageLoaded[index] = true;
        setImageLoaded(updatedImageLoaded);
    };

    // TODO fetch only on screen image

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get<{images: Image[]}>(`${apiUrl}/api/images/all`);
                const imageData = response.data.images;
                const reversedImageData = [...imageData].reverse();
                setImages(reversedImageData);

            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages().then(r => console.log("image fetched"))
    }, []);


    const openOverlay = (index: number) => {
        console.log("open overlay")
        setSelectedImageIndex(index);
        setShowOverlay(true);
    };

    const closeOverlay = () => {
        console.log("close overlay")
        setShowOverlay(false);
    };

    const goToNextImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const goToPreviousImage = () => {
        setSelectedImageIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };


    return (
        <div className="carousel-container gallery">
            {images.map((image, index) => (
                <div className={"images-carousel"} key={index}>
                    <div className={"album-container"}>
                        <img
                            src={imageLoaded[index] ? `${apiUrl}/api/images/original/${image.source}` : `${apiUrl}/api/images/min/${image.source}`}
                            alt={image.description}
                            crossOrigin="anonymous"
                            loading={"lazy"}
                            onClick={() =>openOverlay(index)}
                            onLoad={() => handleImageLoad(index)}
                        />
                    </div>
                </div>
            ))}

            {showOverlay && (
                <div className="overlay">
                    <span className="prev-arrow" onClick={goToPreviousImage}>
                        &#8249;
                    </span>
                    <img
                        src={`${apiUrl}/api/images/original/${images[selectedImageIndex].source}`}
                        alt={images[selectedImageIndex].description}
                        crossOrigin="anonymous"
                        className="full-size-image"
                        loading={"lazy"}
                    />

                    {images[selectedImageIndex].post.country_id === 1 ?
                        (<a href={`/bremen/#post${images[selectedImageIndex].post_id}`} className={"post-btn over"}>Voir le
                            post</a>)
                        :
                        (<a href={`/stanford/post/${images[selectedImageIndex].post_id}`} className={"post-btn over"}>Voir le
                            post</a>)
                    }
                    <span className="next-arrow" onClick={goToNextImage}>
                            &#8250;
                        </span>
                    <span className="close-button" onClick={closeOverlay}>
                            &times;
                        </span>
                </div>
            )}
        </div>
    );
}

export default Album;