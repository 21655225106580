import React, {useState} from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './carousel.css';
import {Image} from "../../Page/Landing/Landing";

interface ImagesProps {
    images: Image[]
}

const apiUrl = process.env.REACT_APP_API_URL;

const CarouselComponent: React.FC<ImagesProps> = ({ images }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showOverlay, setShowOverlay] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const [imageLoaded, setImageLoaded] = useState(Array(images.length).fill(false));

    const handleImageLoad = (index: number) => {
        const updatedImageLoaded = [...imageLoaded];
        updatedImageLoaded[index] = true;
        setImageLoaded(updatedImageLoaded);
    };

    const openOverlay = (index: number) => {
        console.log("open overlay")
        setSelectedImageIndex(index);
        setShowOverlay(true);
    };

    const closeOverlay = () => {
        console.log("close overlay")
        setShowOverlay(false);
    };

    const goToNextImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const goToPreviousImage = () => {
        setSelectedImageIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };


    return (
        <div className="carousel-container">
            <Carousel
                key={selectedIndex}
                selectedItem={selectedIndex}
                onChange={(index) => setSelectedIndex(index)}
                showThumbs={false}
                showStatus={false}
                showArrows={false}
                swipeable={true}
                infiniteLoop
                onClickItem={index => openOverlay(index)}
            >
                {images.map((image, index) => (
                    <div className={"images-carousel"} key={index}>
                        <img
                            src={imageLoaded[index] ? `${apiUrl}/api/images/original/${image.source}` : `${apiUrl}/api/images/min/${image.source}`}
                            alt={image.description}
                            crossOrigin="anonymous"
                            onLoad={() => handleImageLoad(index)}
                        />
                    </div>
                ))}
            </Carousel>
            <div className="dots">
                {images.map((_, index) => (
                    <span
                        key={index}
                        className={index === selectedIndex ? 'dot active' : 'dot'}
                        onClick={() => {
                            console.log('Dot clicked' + index);
                            setSelectedIndex(index);
                        }}
                    ></span>
                ))}
            </div>
            {showOverlay && (
                <div className="overlay">
                    <span className="prev-arrow" onClick={goToPreviousImage}>
                        &#8249;
                    </span>
                    <img
                        src={`${apiUrl}/api/images/original/${images[selectedImageIndex].source}`}
                        alt={images[selectedImageIndex].description}
                        crossOrigin="anonymous"
                        className="full-size-image"
                    />
                    <span className="next-arrow" onClick={goToNextImage}>
                        &#8250;
                    </span>
                    <span className="close-button" onClick={closeOverlay}>
                        &times;
                    </span>
                </div>
            )}
        </div>
    );
};

export default CarouselComponent;
