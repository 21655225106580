import React, {Component, useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import "./header.css"

interface HeaderProps {
    countryView: string;
    setCountryView: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<HeaderProps> = ({ countryView, setCountryView }) => {

    const [imageSrc, setImageSrc] = useState("/images/musiciens.png");
    const [nextCountry, setNextCountry] = useState("bremen")

    useEffect(() => {
        toggleImage()
    }, [countryView]);


    const toggleImage = () => {
        if (countryView === "stanford") {
            setImageSrc("/images/stanford.png");
        } else {
            setImageSrc("/images/musiciens.png");
        }
    };

    const changeCountry = () => {
        if (countryView === "bremen") {
            window.location.href = "/stanford"
        } else {
            window.location.href = "/bremen"
        }
    }

    return (
        <div className="top">
            <div className="topLeft">
                <img src={imageSrc} alt={"Changer le pays"} onClick={changeCountry}/>
            </div>
            <div className="topRight">
                <ul className="topList">
                    <li className="topListItem">
                        <Link className="link" to={"/" + countryView}>
                            ACCUEIL
                        </Link>
                    </li>
                    <li className="topListItem">
                        <Link className="link" to="/album">
                            ALBUM
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Header;