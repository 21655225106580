import React, {useEffect, useState} from 'react';
import {Routes, Route, BrowserRouter, useNavigate} from 'react-router-dom';
import './index.css';

// Import your components for the different pages
import MainPage from './Page/Landing/Landing';
import LoginPage from './Component/Login/Login';
import Register from './Component/Register/Register'
import DashboardPage from './Page/Dashboard/Dashboard';
import Header from "./Component/Header/Header";
import AuthGuard from './Security/AuthGuard';
import Album from "./Page/Album/Album";
import PostPage from "./Component/Stanford/PostPage/PostPage"


function App() {

    const [countryView, setCountryView] = useState<string>("stanford");

    const navigate = useNavigate();

    useEffect(() => {
        const url = window.location.pathname.toLowerCase()

        if (url === "/") {
            navigate(`/stanford`)
        }

        if (url.includes('bremen')) {
            setCountryView('bremen');
        } else {
            setCountryView('stanford');
        }

    }, []);


  return (
      <>
        <Header countryView={countryView} setCountryView={setCountryView}/>
        <Routes>
            <Route path={"/"}/>
            <Route path="/:urlCountry" element={<MainPage />} />
            <Route path="/album" element={<Album />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<Register />} />
            <Route path="/stanford/post/:postId" element={<PostPage />} />
            <Route path="/dashboard" element={<AuthGuard><DashboardPage /></AuthGuard>} />
        </Routes>
      </>
  );
}

export default App;
