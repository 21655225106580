import React, {Component, useEffect, useState} from 'react';
import axios from 'axios';
import "./landing.css"
import Card from "../../Component/Bremen/Card/Card";
import {getToken} from "../../firebase"
import Loading from "../../Component/Loading/Loading";
import Signet from "../../Component/Stanford/Signet/Signet";
import {Params, useParams} from "react-router-dom";

export interface Post {
    id: number;
    title: string;
    text: string;
    date: string;
    reactions: Reaction[];
    tags: string[];
    images: Image[];
    country: Country;
    country_id: number;
}

interface Country {
    id: number;
    name: string;
}

interface Reaction {
    count: number;
    Reaction: {
        name: string;
    };
}

export interface Image {
    post: Post;
    source: string;
    description: string;
    post_id: number;
}

const PAGE_SIZE = 3;


const apiUrl = process.env.REACT_APP_API_URL;


const Landing = () => {

    const [posts, setPosts] = useState<Post[]>([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [newDataIdentical, setNewDataIdentical] = useState(false)
    const [isHash, setIsHash] = useState(false)

    const { urlCountry } = useParams<Params>();

    useEffect(() => {
        document.body.className = urlCountry ?? "bremen";
    }, [urlCountry]);


    useEffect(() => {

        const askForNotificationPermission = async () => {
            await caches.delete("post-cache");

            try {
                await getToken()
            } catch (error) {
                console.error('Impossible d\'obtenir la permission pour les notifications :', error);
            }
        };

        askForNotificationPermission().then(r => console.log("permission asked"));

    }, []);

    useEffect(() => {
        let hash: boolean = getHashId()
        setIsHash(hash)
        fetchPosts(hash).then(r => console.log("fetched"));

    }, [isHash]);


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [page, loading, newDataIdentical]);

    const fetchPosts = async (hash: boolean) => {
        if (loading) return;
        setLoading(true);
        try {

            const response =  await axios.get<Post[]>(`${apiUrl}/api/posts?page=${page}&pageSize=${PAGE_SIZE}&hash=${hash}&country=${urlCountry}`);

            const isNewDataIdentical = response.data.every((post, index) => {
                const lastPost = posts[posts.length - response.data.length + index];
                return JSON.stringify(post) === JSON.stringify(lastPost);
            });

            if (isNewDataIdentical) {
                setNewDataIdentical(true);
                return;
            }

            setPosts((prevPosts) => [...prevPosts, ...response.data]);

            setPage((prevPage) => prevPage + 1);

        } catch (error) {
            console.error('Error fetching posts:', error);
        } finally {
            setLoading(false);
        }
    };


    const handleScroll = () => {
        const scrollY: number = window.scrollY || document.documentElement.scrollTop;
        const pageHeight: number = document.documentElement.scrollHeight;
        const viewportHeight: number = window.innerHeight;
        const distanceFromBottom: number = pageHeight - (scrollY + viewportHeight);
        if (distanceFromBottom < 100 && !loading && !newDataIdentical) {
            fetchPosts(false).then(r => "fetched");
        }
    };

    const getHashId = () => {
        const hash: string = window.location.hash;
        return !!(hash && hash.startsWith("#post"));
    };


    return (
        <div className="landing">
            { isHash ?
                <a href={"/"} className={'refresh-pill'}><img src={"/images/refresh.svg"} alt={"refresh"}/><span>Voir le dernier post</span></a>
                : ''}
            {posts.map((post) => (
                urlCountry === "bremen" ? (
                    <Card key={post.id} post={post} />
                ) : (
                    <Signet key={post.id} post={post} />
                )
            ))}
            { loading && <Loading /> }
        </div>
    );
}

export default Landing;