import { useEffect, useState, useCallback } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

interface ReactionState {
    happy: number;
    thumbs: number;
    calm: number;
}

interface EmojiSelectionState {
    thumbs: boolean;
    calm: boolean;
    happy: boolean;
}

type EmojiType = keyof ReactionState;

interface UseReactionsProps {
    postId: number;
    apiUrl: string;
    initialReactions?: ReactionState;
}

export const useReactions = ({ postId, apiUrl, initialReactions }: UseReactionsProps) => {
    const [reactions, setReactions] = useState<ReactionState>(initialReactions || { happy: 0, thumbs: 0, calm: 0 });
    const [selectedEmoji, setSelectedEmoji] = useState<EmojiSelectionState>({ thumbs: false, calm: false, happy: false });

    const hasReactions = Object.values(reactions).some((count) => count > 0);

    const handleEmojiClick = useCallback(
        (emoji: EmojiType) => {
            const url = `${apiUrl}/api/reactions`;

            setSelectedEmoji((prevSelectedEmoji) => {
                const updatedSelectedEmoji = { ...prevSelectedEmoji, [emoji]: !prevSelectedEmoji[emoji] };

                // Update cookie with selected emojis and post ID
                Cookies.set('userPreferences', JSON.stringify({ selectedEmojis: updatedSelectedEmoji, postId }), { expires: 365 * 10 });

                const updatedReactions = { ...reactions };
                const instruction = updatedSelectedEmoji[emoji] ? 'add' : 'remove';

                axios.post(url, { postId, emoji, instruction })
                    .then((response) => {
                        console.log('API response:', response.data);

                        // Update local reactions based on server response
                        if (instruction === 'add') {
                            updatedReactions[emoji]++;
                        } else {
                            updatedReactions[emoji]--;
                        }
                        setReactions(updatedReactions);
                    })
                    .catch((error) => {
                        console.error('API error:', error);
                    });

                return updatedSelectedEmoji;
            });
        },
        [apiUrl, postId, reactions]
    );

    useEffect(() => {
        const storedUserPreferences = Cookies.get('userPreferences');
        if (storedUserPreferences) {
            const parsedUserPreferences = JSON.parse(storedUserPreferences);
            if (parsedUserPreferences.postId === postId) {
                setSelectedEmoji(parsedUserPreferences.selectedEmojis);
            }
        }

        if (initialReactions) {
            setReactions(initialReactions);
        }
    }, [postId]);

    return { reactions, selectedEmoji, hasReactions, handleEmojiClick };
};
