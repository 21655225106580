import React, {useEffect, useState} from 'react';
import "./signet.css"
import {Post} from "../../../Page/Landing/Landing";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";

interface CardProps {
    post: Post;
}


const MAX_DISPLAY_LENGTH = 45;

type EmojiType = 'happy' | 'thumbs' | 'calm';

const apiUrl = process.env.REACT_APP_API_URL;
const appUrl = process.env.REACT_APP_APP_URL;

interface ViewedPost {
    postId: number;
    isViewed: boolean;
}

const Signet: React.FC<CardProps> = ({ post }) => {

    const [classViewed, setClassViewed] = useState("")
    const navigate = useNavigate();

    const checkViewedPost = () => {
        const storedViewedPost = Cookies.get('viewedPost');
        let parsedViewedPosts: ViewedPost[] = [];

        try {
            parsedViewedPosts = Array.isArray(JSON.parse(storedViewedPost || '[]'))
                ? JSON.parse(storedViewedPost || '[]')
                : [];
        } catch (error) {
            console.error("Error parsing viewed posts:", error);
            parsedViewedPosts = [];
        }

        const postViewed = parsedViewedPosts.find((viewedPost: ViewedPost) => {
            return viewedPost.postId === post.id && viewedPost.isViewed;
        });

        if (postViewed) {
            setClassViewed("viewed");
        }
    };

    const showPost = () => {
        const storedViewedPost = Cookies.get('viewedPost');
        let parsedViewedPosts: ViewedPost[] = [];

        try {
            parsedViewedPosts = Array.isArray(JSON.parse(storedViewedPost || '[]'))
                ? JSON.parse(storedViewedPost || '[]')
                : [];
        } catch (error) {
            console.error("Error parsing viewed posts:", error);
            parsedViewedPosts = [];
        }

        const postViewed = parsedViewedPosts.find((viewedPost: ViewedPost) => {
            return viewedPost.postId === post.id;
        });

        if (!postViewed) {
            parsedViewedPosts.push({ postId: post.id, isViewed: true });
        }

        Cookies.set('viewedPost', JSON.stringify(parsedViewedPosts), { expires: 365 * 10 });

        navigate(`/stanford/post/${post.id}`, { state: post });
    };




    useEffect(() => {

        checkViewedPost()

        scrollToPost().then(r => "hash handler");

    }, []);

    const scrollToPost = async () => {
        let hash = getHashId();
        if (hash) {
            let scrolled = false;
            while (!scrolled) {
                const element = document.getElementById(hash);
                if (element) {
                    element.scrollIntoView();
                    scrolled = true
                }
                await timeout(500)
            }
        }
    };

    function timeout(delay: number) {
        return new Promise( res => setTimeout(res, delay) );
    }

    const getHashId = () => {
        const hash = window.location.hash;
        if (hash && hash.startsWith("#post")) {
            return hash.substring(1)
        }
        return null;
    };



    return (
        <div className={"signet " + classViewed} id={"post" + post.id} onClick={showPost}>
            <div className={"content"}>
                <h3>{post.title}</h3>
                <p className="text">
                    {post.text}
                </p>
            </div>
            <img src="/images/arrow_forward.svg" alt="Ouvrir le post"/>
        </div>

    );
}

export default Signet;